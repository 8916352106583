<style scoped>
	.base {
		background-color: rgb(246, 248, 255);
		text-align: center;
	}

	.logo {
		width: 100%;
		height: calc(100vw * 451.5 / 750);
		max-height: calc(750px * 451.5 / 750);
	}

	.logo > img {
		display: block;
		width: 100%;
		height: auto;
	}

	.button-area {
		height: 52px;
		background: #313035;
		/*opacity: 0.5;*/
		margin-top: -52px;
	}

	.button-base {
		display: inline-block;
		width: 30px;
		height: 15px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #f1bd20;
		line-height: 15px;
		opacity: 0.8;
		font-size: 14px;
		margin-top: 20px;

		text-align: center;
	}

	.today-income {
		width: 100vw;
		height: 15px;
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 15px;
		opacity: 0.8;
		margin-left: 8px;
		margin-top: calc(44px - 100vw * 452 / 750);
		margin-bottom: 32px;
	}

	.income-text {
		width: 100vw;
		height: 34px;
		font-size: 45px;
		letter-spacing: 2px;
		font-family: PingFang SC;
		font-weight: 600;
		color: #e9be3e;
		line-height: 45px;
	}

	.button-onclick {
		border-bottom: 2px solid rgba(32, 64, 189, 1);
		color: rgba(32, 64, 189, 1);
	}

	.task-area {
		margin-top: 3px;

		background-color: white;
		/*overflow: hidden;*/
	}

	.task-box {
		margin: 15px 15px 0 15px;
		height: 78px;
		border-bottom: 1px solid rgba(153, 153, 153, 0.1);
		display: flex;
	}

	.task-context {
		width: 70%;
		height: 70px;
	}

	.img-style {
		width: 15px;
		height: 15px;
		float: left;
		margin-top: 12px;
	}

	.word-area {
		height: 50px;
		margin: 0 0 0 20px;
		/*padding: 20px 15px 20px 15px;*/
	}

	.wordsize {
		/*width: 59%;*/
		text-align: left;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
		font-size: 15px;
		margin-top: 8px;
		word-break: break-all;
		white-space: normal;
		line-height: 21px;
	}

	.wordsize2 {
		/*width: 65%;*/
		text-align: left;
		margin-left: -20px;
		margin-top: 8px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: rgba(153, 153, 153, 0.8);
		/*margin: 8px 0 0 0;*/
		line-height: 18px;
	}

	.task-button-area {
		/*display: in;*/
		/*height: 30px;*/
		text-align: right;
		margin: 0;
		padding: 0;
		width: 40%;
		font-size: 20px;
		word-break: break-all;
		/*overflow : hidden;*/
		/*text-overflow: ellipsis;*/
	}

	.button-icon {
		height: 18px;
		margin-right: 8px;
		margin-top: -4px;
	}

	/deep/ .el-scrollbar__wrap {
		overflow-x: hidden;
		overflow-y: hidden;
	}

	/deep/ .el-scrollbar__thumb {
		display: none;
	}

	.text-description {
		position: fixed;
		top: 0;
		height: 100vh;
		width: 100vw;
		background-color: rgba(0, 0, 0, 0.199);
		z-index: 2000;
	}

	.model-button {
		height: 100%;
		width: 100%;
		background-color: transparent;
		z-index: 1999;
		border: none;
		padding: 0;
	}

	.save-qr-button {
		width: 100%;
		/*height: 40px;*/
		border: none;
		background-color: transparent;
		margin-top: 100px;
		font-size: 15px;
		font-family: Alibaba PuHuiTi;
		font-weight: 400;
		color: #ffffff;
		line-height: 20px;
		opacity: 0.8;
	}
</style>

<template>
	<div class="base">
		<div v-if="isShowQR" class="text-description">
			<button class="model-button" @click.stop="closeQR">
				<div ref="qrimg" style="width: 280px; margin-left: calc(50vw - 140px); border-radius: 15px; background-color: transparent"><qrcodeview></qrcodeview></div>
				<button class="save-qr-button" @click="getQRCode">点击保存二维码</button>
			</button>
		</div>

		<div class="logo" ref="logoarea">
			<img src="@/assets/image/home/bg_home_top.png" alt="" class="logo-img" />
			<p class="today-income">
				今日营业额(元)
				<el-button style="border: none; padding-left: 0px; padding-right: 0px; height: 11px; margin-bottom: 5px; background-color: transparent" @click="changeVisable">
					<img v-if="income_is_visable" style="height: 11px; margin-bottom: 3px" src="@/assets/image/home/icon_eye.png" />
					<img v-if="!income_is_visable" style="height: 11px; margin-bottom: 3px" src="@/assets/image/home/icon_eye_close.png" />
				</el-button>
			</p>
			<p class="income-text">{{ income_text }}</p>
		</div>

		<el-row class="button-area">
			<el-col :span="11">
				<img src="@/assets/image/home/icon_money.png" class="button-icon" />
				<span
					:class="{
						'button-base': true
					}"
					@click="showQR"
				>
					收款
				</span>
			</el-col>
			<el-col :span="2">
				<p style="width: 1px; height: 25px; margin-top: 14px; background-color: white; margin-left: 10px"></p>
				<!--        <el-divider  direction="vertical" content-position="center"></el-divider>-->
			</el-col>
			<el-col :span="11">
				<img src="@/assets/image/home/icon_bills.png" class="button-icon" />
				<span
					:class="{
						'button-base': true
					}"
					@click="showHistoryBill"
				>
					账本
				</span>
			</el-col>
		</el-row>

		<div class="task-area" :style="{ height: listHeight, 'min-height': '400px' }" v-loading="loading" :id="id">
			<el-row style="padding-bottom: 40px">
				<el-col :xs="24" :sm="12" v-for="item of datalist" :key="item['orderNo']">
					<div class="task-box">
						<div class="task-context">
							<img :src="imgurl[item['type']]" class="img-style" />
							<div class="word-area">
								<div class="wordsize">{{ item['date'] }}</div>
								<div class="wordsize2">收款方: {{ merName }}</div>
							</div>
						</div>
						<div class="task-button-area" :style="{ color: '#333333' }">+{{ (parseFloat(item['txnAmtPay']) / 100.0).toFixed(2) }}</div>
					</div>
				</el-col>
			</el-row>
		</div>

		<van-tabbar route active-color="#333333" inactive-color="#eeeeee">
			<van-tabbar-item v-for="(item, index) in tabbar_items" :key="index" :class="['iconfont', item.iconClass]" :to="item.src"
				>{{ item.title }}
				<template slot="icon" slot-scope="props">
					<img :src="props.active ? item.active : item.normal" />
				</template>
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>
<script>
	import BScroll from '@better-scroll/core'
	import PullDown from '@better-scroll/pull-down'
	import Pullup from '@better-scroll/pull-up'
	import ObserveDom from '@better-scroll/observe-dom'
	import qrcodeview from './qrcode_view'
	// import {ref} from 'vue'

	import html2canvas from 'html2canvas'

	export default {
		name: 'home',
		components: {
			qrcodeview
		},
		data() {
			return {
				income_is_visable: true,
				income_text: '',
				income_value: '',
				listHeight: '',
				isPullingUp: false,
				bsScroll: '',
				merName: '',
				logo: {
					icon: require('../../assets/image/pay/icon_merchant.png')
				},
				pageend: false,
				loading: false,
				status: '0',
				id: '1',
				imgurl: {
					1: require('../../assets/image/home/icon_type_alipay.png'),
					2: require('../../assets/image/home/icon_type_wx.png'),
					3: require('../../assets/image/home/icon_type_boa.png')
				},
				datalist: [
					// {
					//   "id": "1",
					//   "type": 1,
					//   "title": "2022.01.05 11:22:42",
					//   "receiver": "灵岩山寺",
					// }, {
					//   "id": "12",
					//   "type": 1,
					//   "title": "测试2",
					//   "receiver": "灵岩山寺",
					//
					// }, {
					//   "id": "11",
					//   "type": 2,
					//   "title": "测试3",
					//   "receiver": "灵岩山寺",
					// }, {
					//   "id": "13",
					//   "type": 2,
					//   "title": "测试4",
					//   "receiver": "灵岩山寺",
					// }, {
					//   "id": "132",
					//   "type": 1,
					//   "title": "测试5",
					//   "receiver": "灵岩山寺",
					// }, {
					//   "id": "111",
					//   "type": 2,
					//   "title": "测试44",
					//   "receiver": "灵岩山寺",
					// }, {
					//   "id": "1323",
					//   "type": 1,
					//   "title": "测试4",
					//   "receiver": "灵岩山寺",
					// }, {
					//   "id": "1232",
					//   "type": 1,
					//   "title": "测试5",
					//   "receiver": "灵岩山寺",
					// }, {
					//   "id": "1311",
					//   "type": 1,
					//   "title": "测试44",
					//   "receiver": "灵岩山寺",
					// },
				],
				tabbar_items: [
					{
						title: '首页',
						normal: require('../../assets/image/tab/icon_home_normal.png'),
						active: require('../../assets/image/tab/icon_home_active.png'),
						src: '/h5/home'
					},
					{
						title: '我的',
						normal: require('../../assets/image/tab/icon_mine_normal.png'),
						active: require('../../assets/image/tab/icon_mine_active.png'),
						src: '/h5/mine'
					}
				]
			}
		},
		methods: {
			getQRCode() {
				console.log(this.$refs.qrimg)
				html2canvas(this.$refs.qrimg).then(canvas => {
					// canvas.style.background = 'black'
					let uri = canvas.toDataURL('image/png')
					// 转成图片，生成图片地址
					let eleLink = document.createElement('a')
					eleLink.href = uri // 转换后的图片地址
					eleLink.download = localStorage.getItem('merchantName')
					document.body.appendChild(eleLink)
					// 触发点击
					eleLink.click()
					// 然后移除
					document.body.removeChild(eleLink)
				})
			},
			changeVisable() {
				this.income_is_visable = !this.income_is_visable
				this.income_text = this.income_is_visable ? (this.income_value / 100.0).toFixed(2).toString() : '****'
			},
			showQR() {
				localStorage.setItem('is_temple', 'false')
				this.$store.commit('inputQRView', true)
			},
			closeQR() {
				this.$store.commit('inputQRView', false)
			},
			showHistoryBill() {
				this.$router.push({ path: '/h5/tradelist' })
			},
			getContentHeight() {
				let logohig = this.$refs.logoarea.clientHeight
				let windowheight = document.documentElement.clientHeight
				let taskheight = windowheight - logohig
				this.listHeight = taskheight.toString() + 'px'
			},
			backtop() {},
			init_bsscroll() {},
			scrollHandler(pos) {},
			async pullingDownHandler() {},
			async finishPullDown() {},
			async pullingUpHandler() {},
			refreshData() {
				this.merName = localStorage.getItem('merchantName')
				let _this = this
				let params = { isAsc: false }
				Http.post('/frontend/order/dailyStatistic', params)
					.then(result => {
						if (result.code === 200) {
							// console.log(result)
							_this.datalist = []
							_this.processList(result.data.list)
							_this.income_value = result.data.total
							_this.income_text = _this.income_is_visable ? (_this.income_value / 100.0).toFixed(2).toString() : '****'
						} else {
							_this.$message.error(result.message || '查询失败！')
						}
					})
					.catch(error => {
						// console.log(error)
						_this.$message.error('请求失败')
					})
					.finally(() => {
						_this.fullscreenLoading = false
					})
			},
			processList(infoList) {
				let i = 0
				for (let item of infoList) {
					// console.log(item)
					if (item['payType'] == 'EP138' || item['payType'] == 'EP139' || item['payType'] == 'EP140') {
						item['type'] = '2'
					} else if (item['payType'] == 'EP159' || item['payType'] == 'EP160' || item['payType'] == 'EP161') {
						item['type'] = '1'
					} else {
						item['type'] = '3'
					}

					this.datalist.push(item)
				}
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.init_bsscroll()
			})
			this.getContentHeight()

			this.refreshData()
		},
		created() {
			document.title = '首页'
		},
		computed: {
			isShowQR() {
				return this.$store.state.showQR
			}
		}
	}
</script>
